import React, { useState, useEffect } from "react";
import { Button, Modal, Input } from "antd";
import { useDispatch } from "react-redux";
import { setSiteAccount } from "../reducers/siteSlice";
import { updateSiteService, getSiteService } from "../services/site";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  KeyOutlined,
} from "@ant-design/icons";
const EditCreate = ({ payload }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_name, setSiteName] = useState("");
  const [site_address, setSiteAddress] = useState("");
  const [site_uuid, setSiteUUID] = useState("");
  const [site_username, setSiteUsername] = useState("");
  const [site_password, setSitePassword] = useState("");
  const [site_note, setSiteNote] = useState("");
  const [account, setAccount] = useState(payload);
  const [accountLimit, setAccountLimit] = useState(payload);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAccount(payload.account);
    setAccountLimit(payload.accountLimit);
    setSiteName(payload.site_name);
    setSiteAddress(payload.site_address);
    setSiteUsername(payload.site_username);
    setSitePassword(payload.site_password);
    setSiteNote(payload.site_note);
    setSiteUUID(payload.site_uuid);
    
  }, [payload]);

  const handleSiteAccountChange = async () => {
    setLoading(true);
    const data = await getSiteService(account.account_uuid);
    dispatch(setSiteAccount(data));
    setLoading(false);
  };

  const handleUpdateSite = async () => {
    setLoading(true);

    await updateSiteService({
      account_uuid: account.account_uuid,
      site_uuid: site_uuid,
      site_name: site_name,
      site_address: site_address,
      site_username: site_username,
      site_password: site_password,
      site_note: site_note,
    });
    await handleSiteAccountChange();

    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleUpdateSite();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} onClick={showModal}>
        แก้ไข
      </Button>
      <Modal
        title="แก้ไขบัญชีหน่วยงาน"
        open={isModalOpen}
        okText="แก้ไข"
        cancelText="ยกเลิก"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่อหน่วยงาน:</p>
        <Input
          value={site_name}
          onChange={(e) => {
            setSiteName(e.target.value);
          }}
          placeholder="ชื่อหน่วยงาน"
        />
        <p>ที่่อยู่หน่วยงาน:</p>
        <Input
          value={site_address}
          onChange={(e) => {
            setSiteAddress(e.target.value);
          }}
          placeholder="ที่่อยู่หน่วยงาน"
        />
        <p>ชื่อผู้ใช้:</p>
        <Input
          value={site_username}
          onChange={(e) => {
            setSiteUsername(e.target.value);
          }}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="username"
        />
        <p>รหัสผ่าน:</p>
        <Input.Password
          value={site_password}
          onChange={(e) => {
            setSitePassword(e.target.value);
          }}
          prefix={<KeyOutlined className="site-form-item-icon" />}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          placeholder="password"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={site_note}
          onChange={(e) => {
            setSiteNote(e.target.value);
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default EditCreate;
