import React from "react";
import { Layout, Menu, Image } from "antd";
import { AppstoreOutlined, SettingFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const menuItems = [
    {
      key: "1",
      icon: <AppstoreOutlined />,
      title: "สรุปข้อมูล",
      link: "/",
    },
    {
      key: "sub1",
      icon: <SettingFilled />,
      title: "ตั้งค่าระบบ",
      disabled: localStorage.getItem("accountType") === "0",
      items: [
        {
          key: "3",
          title: "ลงทะเบียนหน่วยงาน",
          link: "/system/sites",
        },
        {
          key: "4",
          title: "ลงทะเบียนอุปกรณ์",
          link: "/system/devices",
        },
        {
          key: "5",
          title: "ตั้งค่าโปรแกรม",
          link: "/system/program",
        },
        {
          key: "6",
          title: "ตั้งค่าแจ้งเตือน",
          link: "/system/notify",
        },
      ],
    },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={50}
    >
      <Link to="/" className="flex items-center justify-center">
        {collapsed && (
          <Image preview={false} width={50} src="/short-logo.png" />
        )}
        {!collapsed && <Image preview={false} src="/logo.png" />}
      </Link>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        {menuItems.map((item) => {
          if (item.items) {
            return (
              <Menu.SubMenu disabled={item.disabled} key={item.key} icon={item.icon} title={item.title}>
                {item.items.map((subItem) => (
                  <Menu.Item key={subItem.key}>
                    <Link to={subItem.link}>{subItem.title}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          } else {
            return (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.link}>{item.title}</Link>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
