import React, { useState, useEffect, useRef } from "react";
import { Modal, Table, Tag, Button } from "antd";
import moment from "moment";
import BBox from "../annotation/BBox";

const CheckPointList = ({ title, data }) => {
  const [open, setOpen] = useState(false);
  const [imgOpen, setImgOpen] = useState(false);
  const [img, setImg] = useState("");
  const [selectData, setSelectData] = useState([]);
  const [isAnnotation, setIsAnnotation] = useState(false);

  const columns = [
    {
      title: "วันที่",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => (
        <div>
          {moment(created_at.replace("+00:00", "")).format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        </div>
      ),
    },
    {
      title: "ประจำจุด",
      dataIndex: "checkpoint_name",
      key: "checkpoint_name",
    },
    {
      title: "รายงาน",
      dataIndex: "name",
      key: "name",
      render: (
        _,
        {
          report_name,
          report_message,
          report_cause,
          report_where,
          report_time,
          report_who,
        }
      ) => (
        <div>
          {report_name ? (
            <div>
              <p className="font-bold mb-0">รายงานสถานการณ์:</p> {report_name}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">สาเหตุ:</p> {report_cause}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">สถานที่เกิดเหตุ:</p> {report_where}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">เวลาที่เกิดเหตุ:</p> {report_time}
              <p className="m-0">-------</p>
              <p className="font-bold mb-0">ผู้รับผิดชอบหรือรับทราบ:</p>{" "}
              {report_who}
            </div>
          ) : (
            <div>
              <p className="font-bold mb-0">บันทึกข้อความ:</p> {report_message}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "ประเภทรายงาน",
      key: "report_status",
      dataIndex: "report_status",
      render: (_, { report_status }) => (
        <>
          {
            <Tag
              color={report_status === "normal" ? "green" : "red"}
              key={report_status}
            >
              {report_status.toUpperCase()}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "ภาพถ่าย",
      dataIndex: "report_img",
      key: "report_img",
      render: (_, { report_img, detection }) => {
        return (
          <div>
            {report_img.map((img, index) => (
              <a
                onClick={() => {
                  setImg(
                    `https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/${img}`
                  );
                  setSelectData(detection[index]);
                  setImgOpen(true);
                  setIsAnnotation(false);
                }}
              >
                <img
                  width={100}
                  src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/${img}`}
                  key={index}
                />
                <br />
              </a>
            ))}
          </div>
        );
      },
    },
    {
      title: "VisionLLM",
      key: "llm",
      dataIndex: "llm",
      render: (_, { llm }) => (
        <div className="grid grid-cols-1 gap-2">
          {llm?.map((text, index) => (
            <div>{text}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Detection",
      key: "cls",
      dataIndex: "cls",
      render: (_, { cls }) => {
        // สร้าง object ที่เก็บจำนวนครั้งของแต่ละ keyword
        const keywordCounts = {};
        cls?.forEach((keyword) => {
          keywordCounts[keyword] = (keywordCounts[keyword] || 0) + 1;
        });
        // ดึงคีย์เวิร์ดที่พบมาใช้
        const foundKeywords = Object.keys(keywordCounts);
        // สร้างสีจากจำนวนคีย์เวิร์ดที่พบ

        return (
          <div className="grid grid-cols-1 gap-2">
            {foundKeywords.map((keyword, index) => {
              const count = keywordCounts[keyword] || 0;
              // นำเลข index มาใช้ในการเลือกสี
              const color =
                keyword === "บุคคล"
                  ? "red"
                  : keyword === "รถยนต์" || keyword === "รถจักรยานยนต์"
                  ? "blue"
                  : "";

              return (
                <Tag key={index} style={{ backgroundColor: color }}>
                  <div className="flex items-center justify-center text-center ">
                    {`${count}x${keyword}`}
                  </div>
                </Tag>
              );
            })}
          </div>
        );
      },
    },
  ];

  const canvasRef = useRef(null);
  useEffect(() => {
    if (selectData?.length > 0) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = img;
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        selectData.forEach(async (item) => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");

          var [x, y, w, h] = item.box;

          const left = x - w / 2;
          const top = y - h / 2;
          const width = w;
          const height = h;

          // Adjust bounding box position according to original image dimensions
          const newLeft = left * (image.width / 640);
          const newTop = top * (image.height / 640);
          const newWidth = width * (image.width / 640);
          const newHeight = height * (image.height / 640);

          const x1 = newLeft;
          const y1 = newTop;

          const rectWidth = newWidth;
          const rectHeight = newHeight;

          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.strokeRect(x1, y1, rectWidth, rectHeight);

          ctx.font = "16px Arial";
          ctx.fillStyle = "red";
          ctx.fillText(`${item.class_name}`, x1, y1 - 5);
        });
      };
    } else {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      const image = new Image();
      image.src = img;
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
      };
    }
  }, [selectData, img]);

  return (
    <div>
      <a onClick={() => setOpen(true)}>ดูรายละเอียด</a>
      <Modal
        closeIcon={false}
        title={
          <div className="flex justify-between">
            <div>Detection Report</div>
            <Button onClick={() => setIsAnnotation(!isAnnotation)}>คลิกเพื่อสอน AI</Button>
          </div>
        }
        centered
        open={imgOpen}
        onOk={() => setImgOpen(false)}
        onCancel={() => setImgOpen(false)}
      >
        {!isAnnotation && <canvas className="w-[100%]" ref={canvasRef} />}
        {isAnnotation && <BBox IMAGE_URL={img} />}
      </Modal>

      <Modal
        title={title}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Table rowKey="id" columns={columns} dataSource={data} />
      </Modal>
    </div>
  );
};

export default CheckPointList;
