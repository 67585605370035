import React, { useState } from "react";
import { Tag, Drawer, Space, Image } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";

const App = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const data = [
    {
      key: "1",
      title: "EP.1 การเข้าสู่ระบบสำหรับบริษัท",
      duration: "0.33 นาที",
      preview: "https://ai-facilflow.web.app/ep1-1.png",
      link: "https://www.youtube.com/watch?v=R4iswin5owk&list=PLVYWkn-4EWXG3gykHtWj3YLDQZ8XxVvbi&index=1",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "2",
      title: "EP.2 การเข้าสู่ระบบสำหรับหน่วยงาน",
      duration: "0.32 นาที",
      preview: "https://ai-facilflow.web.app/ep1-2.png",
      link: "https://www.youtube.com/watch?v=SjpC7f9TnN0&list=PLVYWkn-4EWXG3gykHtWj3YLDQZ8XxVvbi&index=2",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "3",
      title: "EP.3 ขั้นตอนการลงทะเบียนหน่วยงาน",
      duration: "0.49 นาที",
      preview: "https://ai-facilflow.web.app/ep2.png",
      link: "https://www.youtube.com/watch?v=4XxywTlbmyo&list=PLVYWkn-4EWXG3gykHtWj3YLDQZ8XxVvbi&index=3",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "4",
      title: "EP.4 ขั้นตอนการลงทะเบียนอุปกรณ์",
      duration: "0.57 นาที",
      preview: "https://ai-facilflow.web.app/ep3.png",
      link: "https://www.youtube.com/watch?v=T2VNqGa7n2E&list=PLVYWkn-4EWXG3gykHtWj3YLDQZ8XxVvbi&index=4",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "5",
      title: "EP.5 ขั้นตอนการตั้งค่าโปรแกรม (สร้างจุดตรวจ)",
      duration: "0.55 นาที",
      preview: "https://ai-facilflow.web.app/ep4.png",
      link: "https://www.youtube.com/watch?v=3ilvSmmXVNk&list=PLVYWkn-4EWXG3gykHtWj3YLDQZ8XxVvbi&index=5",
      disable: localStorage.getItem("accountType") === "1",
    },
    {
      key: "6",
      title: "EP.6 ตั้งค่าการแจ้งเตือนผ่าน Line Notify",
      duration: "0.54 นาที",
      preview: "https://ai-facilflow.web.app/ep5.jpg",
      link: "https://www.youtube.com/watch?v=kvjPEFbsM_Q&list=PLVYWkn-4EWXG3gykHtWj3YLDQZ8XxVvbi&index=6",
      disable: localStorage.getItem("accountType") === "1",
    }
  ];

  return (
    <>
      <Space>
        <Tag icon={<YoutubeOutlined />} color="#cd201f" onClick={showDrawer}>
          วิดีโอสอนการใช้งาน
        </Tag>
      </Space>
      <Drawer
        title="วิดีโอสอนการใช้งาน"
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <div className="grid grid-cols-1 items-center">
          {data.map((item, index) => (
            <div key={index}>
              {item.disable && (
                <div className="grid grid-cols-2 items-center space-x-2">
                  <a href={item.link} target="_blank">
                    <Image preview={false} src={item.preview} />
                  </a>
                  <div>
                    <h4>{item.title}</h4>
                    <h6>{item.duration}</h6>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};
export default App;
