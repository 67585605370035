import React from "react";
import { Layout, Upload, Button } from "antd";
import { uploadImageService } from "../services/upload";
const { Content: AntdContent } = Layout;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="text-2xl">ข้อมูลส่วนตัว</div>
      <Upload
        customRequest={dummyRequest}
        onChange={async (info) => {
          if (info.file.status === "done") {
            const imageUrl = await uploadImageService(
              info.file.originFileObj,
              "facilflow",
              `account/${info.file.name}`
            );
          }
        }}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        accept="image/x-png,image/jpeg"
        capture="environment"
      >
        <Button>Click to Upload</Button>
      </Upload>
    </AntdContent>
  );
};

export default ContentComp;
