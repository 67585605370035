import React, { useState, useEffect } from "react";
import { Button, Modal, Input } from "antd";

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  KeyOutlined,
} from "@ant-design/icons";
const EditCreate = ({ payload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_name, setSiteName] = useState("");
  const [site_address, setSiteAddress] = useState("");
  const [site_username, setSiteUsername] = useState("");
  const [site_password, setSitePassword] = useState("");
  const [site_note, setSiteNote] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSiteName(payload.site_name);
    setSiteAddress(payload.site_address);
    setSiteUsername(payload.site_username);
    setSitePassword(payload.site_password);
    setSiteNote(payload.site_note);
  }, [payload]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        ดูข้อมูล
      </Button>
      <Modal
        title="บัญชีหน่วยงาน"
        open={isModalOpen}
        okText="แก้ไข"
        cancelText="ยกเลิก"
        okButtonProps={{
          disabled: true,
        }}
        onCancel={handleCancel}
      >
        <p>ชื่อหน่วยงาน:</p>
        <Input
          disabled
          value={site_name}
          onChange={(e) => {
            setSiteName(e.target.value);
          }}
          placeholder="ชื่อหน่วยงาน"
        />
        <p>ที่่อยู่หน่วยงาน:</p>
        <Input
          disabled
          value={site_address}
          onChange={(e) => {
            setSiteAddress(e.target.value);
          }}
          placeholder="ที่่อยู่หน่วยงาน"
        />
        <p>ชื่อผู้ใช้:</p>
        <Input
          disabled
          value={site_username}
          onChange={(e) => {
            setSiteUsername(e.target.value);
          }}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="username"
        />
        <p>รหัสผ่าน:</p>
        <Input.Password
          value={site_password}
          onChange={(e) => {
            setSitePassword(e.target.value);
          }}
          prefix={<KeyOutlined className="site-form-item-icon" />}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          placeholder="password"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          disabled
          value={site_note}
          onChange={(e) => {
            setSiteNote(e.target.value);
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default EditCreate;
