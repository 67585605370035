import React, { useState, useEffect } from "react";
import { Layout, Select, Image, Alert, Tooltip } from "antd";
import CardMonitor from "../../components/dashboard/CardMonitor";
import {
  AimOutlined,
  CheckCircleOutlined,
  ExclamationOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import CountUp from "react-countup";
// import MapMonitor from "../../components/dashboard/MapMonitor";
import MapDemo from "../../components/dashboard/MapDemoo";
import { getGeoService } from "../../services/geolocation";
import {
  getPatrolService,
  getPatrolFilterTodayService,
} from "../../services/dashboard";
import CheckPointList from "../../components/dashboard/CheckPointList";
import Download from "../../components/Download";

const { Content: AntdContent } = Layout;

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const formatter = (value) => <CountUp end={value} separator="," />;
  const [geo, setGeo] = useState([]);
  const [mode, setMode] = useState("1");
  const [patrolCheck, setPatrolCheck] = useState([]);
  const [patrolNormal, setPatrolNormal] = useState([]);
  const [patrolAbnormal, setPatrolAbnormal] = useState([]);

  const generateKeywordCounts = (cls) => {
    const keywordCounts = {};
    cls?.forEach((keyword) => {
      keywordCounts[keyword] = (keywordCounts[keyword] || 0) + 1;
    });

    return (
      <div className="ml-4">
        Detection:
        {Object.entries(keywordCounts).map(
          ([keyword, count]) => ` ${count}x${keyword} `
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getGeoService(
        JSON.parse(localStorage.getItem("site"))
      );
      if (result.length === 0) {
        setGeo([]);
      } else {
        setGeo(result);
      }
    };
    fetchData();
    setInterval(() => {
      fetchData();
    }, 15 * 1000);
  }, [localStorage.getItem("site")]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPatrolService(
        JSON.parse(localStorage.getItem("site"))
      );
      setPatrolCheck(result);

      const resultNormal = await getPatrolFilterTodayService(
        JSON.parse(localStorage.getItem("site")),
        "normal"
      );
      setPatrolNormal(resultNormal);

      const resultAbnormal = await getPatrolFilterTodayService(
        JSON.parse(localStorage.getItem("site")),
        "abnormal"
      );
      setPatrolAbnormal(resultAbnormal);
    };
    setInterval(() => {
      fetchData();
    }, 15 * 1000);
    fetchData();
  }, [localStorage.getItem("site")]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="grid grid-cols-3 gap-3">
        <CardMonitor
          title={<div>จำนวนจุด check-in (ครั้ง)</div>}
          color={"#FFC13F"}
          icon={
            <Tooltip title={"ข้อมูลนี้คือจำนวนทั้งหมดตั้งแต่เริ่มต้นหน่วยงาน"}>
              <AimOutlined />
            </Tooltip>
          }
          description={
            <div className="text-[28px]">{formatter(patrolCheck.length)}</div>
          }
          actions={
            <CheckPointList title={"รายงานจุด check-in"} data={patrolCheck} />
          }
        />
        <CardMonitor
          title={
            <div className="flex items-center justify-between">
              <div>จำนวนเหตุการณ์ทั่วไป (ครั้ง)</div>
              <div className="w-8 h-8 flex items-center justify-center rounded-[6px] text-black text-[24px]">
                <Download
                  tip={"โหลดรายงานเหตุการณ์ทั่วไป"}
                  icon={<CloudDownloadOutlined />}
                  status={"normal"}
                />
              </div>
            </div>
          }
          color={"#008000"}
          icon={<CheckCircleOutlined />}
          description={
            <div className="text-[28px]">{formatter(patrolNormal.length)}</div>
          }
          actions={
            <CheckPointList
              title={"รายงานเหตุการณ์ทั่วไป"}
              data={patrolNormal}
            />
          }
        />
        <CardMonitor
          title={
            <div className="flex items-center justify-between">
              <div>จำนวนเหตุการณ์ผิดปกติ (ครั้ง)</div>
              <div className="w-8 h-8 flex items-center justify-center rounded-[6px] text-black text-[24px]">
                <Download
                  tip={"โหลดรายงานเหตุการณ์ผิดปกติ"}
                  icon={<CloudDownloadOutlined />}
                  status={"abnormal"}
                />
              </div>
            </div>
          }
          color={"#FF0000"}
          icon={<ExclamationOutlined />}
          description={
            <div className="text-[28px]">
              {formatter(patrolAbnormal.length)}
            </div>
          }
          actions={
            <CheckPointList
              title={"รายงานเหตุการณ์ผิดปกติ"}
              data={patrolAbnormal}
            />
          }
        />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        <CardMonitor
          color={"#FF0000"}
          icon={<ExclamationOutlined />}
          title={
            <div className="flex justify-between">
              <div>รายงานเหตุการณ์ผิดปกติ</div>
            </div>
          }
          actions={
            <div className="overflow-y-scroll max-h-[410px] space-y-2 text-left">
              {patrolAbnormal?.map((item, index) => (
                <div key={index}>
                  <Alert
                    style={{ width: "43vw" }}
                    message={<div className="ml-4">{item.report_name}</div>}
                    description={
                      <>
                        <div className="ml-4">{`VisionLLM: ${item.llm}`}</div>
                        {generateKeywordCounts(item.cls)}
                      </>
                    }
                    type="error"
                    icon={
                      <Image
                        width={200}
                        src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/${item.report_img[0]}`}
                      />
                    }
                    showIcon
                    closable
                  />
                </div>
              ))}
            </div>
          }
        />
        <CardMonitor
          className="col-span-2"
          title={
            <div className="flex justify-between">
              <div>แผนที่การตรวจตรา</div>
              <div>
                <Select
                  className="ml-4"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="ตำแหน่งล่าสุด"
                  onChange={(value) => setMode(value)}
                  value={mode}
                  options={[
                    { value: "1", label: "ตำแหน่งล่าสุด" },
                    { value: "2", label: "เส้นทางการตรวจตรา" },
                    { value: "3", label: "ความถี่การตรวจตรา" },
                  ]}
                />
              </div>
            </div>
          }
          actions={
            <div className="w-[100vw]">
              <MapDemo mode={mode} now={geo} />
            </div>
          }
        />
      </div>
    </AntdContent>
  );
};

export default ContentComp;
