import React from "react";
import { Layout, Button, Select, Avatar, Dropdown, message, Space } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import Drawer from "../Drawer";

import { useSelector, useDispatch } from "react-redux";
import { setSystem } from "../../reducers/systemSlice";
import { setSite } from "../../reducers/siteSlice";
import Search from "../dashboard/Search";
import Upload from "../dashboard/Upload";

const onClick = ({ key }) => {
  if (key === "1") {
    message.info("แก้ไขข้อมูลส่วนตัว");
  } else if (key === "2") {
    message.info("ติดต่อเจ้าหน้าที่");
  } else if (key === "3") {
    message.info("ออกจากระบบ");
    localStorage.clear();
    window.location.href = "/";
  }
};

const items = [
  {
    label: "แก้ไขข้อมูลส่วนตัว",
    key: "1",
    icon: <UserOutlined />,
  },
  {
    label: "ติดต่อเจ้าหน้าที่",
    key: "2",
    icon: <PhoneOutlined />,
  },
  {
    label: "ออกจากระบบ",
    key: "3",
    icon: <LogoutOutlined />,
  },
];

const { Header } = Layout;

const HeaderComp = ({ collapsed, setCollapsed, colorBgContainer }) => {
  const dispatch = useDispatch();
  const selectedSystem = useSelector((state) => state.system.system);
  const selectedSite = useSelector((state) => state.site.site);
  const siteAccount = useSelector((state) => state.site.siteAccount);

  const handleSystemChange = (value) => {
    dispatch(setSystem(value));
  };

  const handleSiteChange = (value) => {
    dispatch(setSite(value));
  };

  return (
    <Header style={{ padding: 0, background: colorBgContainer }}>
      <div className="flex justify-between">
        <div className="flex items-center">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />

          <Upload />
          <Search />

          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="หน่วยงาน"
            value={siteAccount.length > 0 ? selectedSite : "กำลังโหลดข้อมูล..."}
            onChange={handleSiteChange}
          >
            {siteAccount?.map((item) => (
              <Select.Option key={item.site_uuid} value={item.site_uuid}>
                {item.site_name}
              </Select.Option>
            ))}
          </Select>

          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="ระบบ"
            value={selectedSystem}
            onChange={handleSystemChange}
            options={[
              { value: 1, label: "VMS" },
              { value: 2, label: "Guard Patrolling" },
            ]}
          />
        </div>
        <div className="flex items-center mr-4 space-x-2">
          <Drawer />
          <Dropdown placement="bottom" menu={{ items, onClick }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Avatar
                  style={{ backgroundColor: "#001529" }}
                  icon={<UserOutlined />}
                />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComp;
