import { createClient } from "@supabase/supabase-js";
import Compressor from "compressorjs";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const uploadImageService = async (file, bucket, object) => {
  new Compressor(file, {
    quality: 0.3,
    success: async (compressedResult) => {
      const { data, error } = await supabase.storage
        .from(bucket)
        .upload(object, compressedResult);
      console.log(data);
      alert("อัพโหลดรูปภาพสำเร็จ");
      return data;
    },
  });
};
