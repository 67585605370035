import React, { useState } from "react";
import { Tooltip, Modal, DatePicker, Input, Select } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import { message } from "antd";
import { re } from "mathjs";

const { RangePicker } = DatePicker;

export const Pdf = async (payload, status) => {
  try {
    return await axios.post(
      `https://export.rushai.dev/${status}/generate`,
      payload,
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    return {
      status: error.response.status,
    };
  }
};

const Download = ({ tip, icon, status }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [file, setFile] = useState("pdf");
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setLoading(true);
    if (value.length == 0) {
      messageApi.open({
        type: "error",
        content: "กรุณาเลือกช่วงเวลา",
      });
      setLoading(false);
      return;
    }
    Pdf(
      {
        account_uuid: JSON.parse(localStorage.getItem("account")).account_uuid,
        site_uuid: JSON.parse(localStorage.getItem("site")),
        start_date: value[0].format("DD/MM/YYYY HH:mm"),
        end_date: value[1].format("DD/MM/YYYY HH:mm"),
        mode: file,
      },
      status
    ).then((response) => {
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          status == "normal"
            ? file == "pdf"
              ? "รายงานเหตุการณ์ทั่วไป.pdf"
              : "รายงานเหตุการณ์ทั่วไป.xlsx"
            : file == "pdf"
            ? "รายงานเหตุการณ์ผิดปกติ.pdf"
            : "รายงานเหตุการณ์ผิดปกติ.xlsx"
        ); // กำหนดชื่อไฟล์ที่จะดาวน์โหลด

        // เพิ่ม element ลิงก์เข้าไปในเอกสาร
        document.body.appendChild(link);

        // คลิกที่ลิงก์เพื่อดาวน์โหลดไฟล์
        link.click();

        // ลบ element ลิงก์ทิ้ง
        document.body.removeChild(link);
        messageApi.open({
          type: "success",
          content: "ดาวน์โหลดสำเร็จ",
        });
        setIsModalOpen(false);
        setLoading(false);
      } else {
        messageApi.open({
          type: "error",
          content: "ไม่พบข้อมูลในช่วงเวลาที่ระบุ",
        });
        setIsModalOpen(false);
        setLoading(false);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const onChange = (value) => {
    setFile(value);
  };
  const onOk = (value) => {
    setValue(value);
  };
  return (
    <>
      <Tooltip title={"คลิกเพื่อ" + tip}>
        <a onClick={showModal}>{icon}</a>
      </Tooltip>
      {contextHolder}
      <Modal
        title={tip}
        confirmLoading={loading}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="grid grid-cols-2 gap-2 items-center">
          <div>เลือกประเภทไพล์</div>
          <Select
            defaultValue="pdf"
            onChange={onChange}
            options={[
              { value: "pdf", label: "pdf" },
              { value: "excel", label: "excel" },
            ]}
          />
          <div>เลือกช่วงเวลา</div>
          <RangePicker
            showTime={{
              format: "HH:mm",
            }}
            format="DD-MM-YYYY HH:mm"
            onOk={onOk}
          />
          <div>ระบุ mail</div>
          <Input
            placeholder=" กรณีที่ต้องการส่งข้อมูลผ่าน mail"
            prefix={<MailOutlined />}
          />
        </div>
      </Modal>
    </>
  );
};
export default Download;
