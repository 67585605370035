import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Select } from "antd";
import {
  setDeviceService,
  getDeviceService,
  getDeviceByAccountService,
} from "../services/device";
import { useDispatch } from "react-redux";
import { setDevice } from "../reducers/deviceSlice";
import { message } from "antd";

const DeviceCreate = ({ system, site_uuid, accountLimit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [device_name, setDeviceName] = useState("");
  const [mode, setMode] = useState("v1");
  const [device_description, setDeviceDescription] = useState("");

  const [TotalDevice, setTotalDevice] = useState(0);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const handleSiteAccountChange = async () => {
    setLoading(true);
    const data = await getDeviceService(system, site_uuid);
    dispatch(setDevice(data));
    setLoading(false);
  };

  const handleAddDevice = async () => {
    setLoading(true);
    let limit = 0;
    if (system == "vms") {
      limit = accountLimit.create_vms_device_limit;
    } else {
      limit = accountLimit.create_patrol_device_limit;
    }

    console.log(TotalDevice, limit);

    if (TotalDevice < limit && localStorage.getItem("site") != null) {
      await setDeviceService(system, {
        account_uuid: JSON.parse(localStorage.getItem("account"))[
          "account_uuid"
        ],
        site_uuid: site_uuid,
        device_name: device_name,
        mode: mode,
        device_description: device_description,
      });
      await handleSiteAccountChange();
      await getDeviceByAccountService(
        system,
        JSON.parse(localStorage.getItem("account"))["account_uuid"]
      ).then((data) => {
        setTotalDevice(data.length);
      });
      messageApi.open({
        type: "success",
        content: "เพิ่มอุปกรณ์สำเร็จ",
      });
    } else {
      messageApi.open({
        type: "error",
        content: "กรุณาเลือกหน่วยงานก่อนเพิ่มอุปกรณ์",
      });
    }
    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleAddDevice();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getDeviceByAccountService(
      system,
      JSON.parse(localStorage.getItem("account"))["account_uuid"]
    ).then((data) => {
      setTotalDevice(data.length);
    });
  }, [TotalDevice, system]);

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มอุปกรณ์
      </Button>
      {contextHolder}
      <Modal
        title="สร้างอุปกรณ์"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่ออุปกรณ์:</p>
        <Input
          value={device_name}
          onChange={(e) => {
            setDeviceName(e.target.value);
          }}
          placeholder="ชื่อหน่วยงาน"
        />
        <p>เลือกเวอร์ชั่นการทำงาน:</p>
        <Select
          value={mode}
          onChange={(value) => {
            setMode(value);
          }}
          style={{ width: "100%" }}
          placeholder="เลือกเวอร์ชั่นการทำงาน"
          options={[
            { label: "v1", value: "v1" },
            { label: "v2", value: "v2" },
            { label: "v3", value: "v3" },
          ]}
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={device_description}
          onChange={(e) => {
            setDeviceDescription(e.target.value);
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default DeviceCreate;
