import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Select } from "antd";
import { updateDeviceService, getDeviceService } from "../services/device";
import { useDispatch } from "react-redux";
import { setDevice } from "../reducers/deviceSlice";

const DeviceEdit = ({ system, payload }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_uuid, setSiteUUID] = useState("");
  const [device_name, setDeviceName] = useState("");
  const [mode, setDeviceMode] = useState("v1");
  const [device_description, setDeviceDescription] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSiteUUID(payload.site_uuid);
    setDeviceName(payload.device_name);
    setDeviceMode(payload.mode);
    setDeviceDescription(payload.device_description);
  }, [payload]);

  const handleDeviceChange = async () => {
    setLoading(true);
    const data = await getDeviceService(system, site_uuid);
    dispatch(setDevice(data));
    setLoading(false);
  };

  const handleUpdateDevice = async () => {
    setLoading(true);

    await updateDeviceService(system, {
      site_uuid: site_uuid,
      device_name: device_name,
      mode: mode,
      device_description: device_description,
    });
    await handleDeviceChange();

    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleUpdateDevice();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        แก้ไขอุปกรณ์
      </Button>
      <Modal
        title="สร้างอุปกรณ์"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่ออุปกรณ์:</p>
        <Input
          value={device_name}
          onChange={(e) => {
            setDeviceName(e.target.value);
          }}
          placeholder="ชื่อหน่วยงาน"
        />
        <p>เลือกเวอร์ชั่นการทำงาน:</p>
        <Select
          value={mode}
          onChange={(value) => {
            setDeviceMode(value);
          }}
          style={{ width: "100%" }}
          placeholder="เลือกเวอร์ชั่นการทำงาน"
          options={[
            { label: "v1", value: "v1" },
            { label: "v2", value: "v2" },
            { label: "v3", value: "v3" },
          ]}
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={device_description}
          onChange={(e) => {
            setDeviceDescription(e.target.value);
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default DeviceEdit;
