import React, { useState } from "react";
import { Button, Modal } from "antd";

const Delete = ({ system, func, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setLoading(true);
    if (system === null) await func(id);
    else await func(system, id);
    await window.location.reload(false);
    setLoading(false);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" danger onClick={showModal}>
        ลบข้อมูล
      </Button>
      <Modal
        title="ยืนยันการลบข้อมูล"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>คุณต้องการลบข้อมูลหรือไม่?</p>
      </Modal>
    </>
  );
};
export default Delete;
