import React, { useState, useEffect } from "react";
import { Button, Modal, Input } from "antd";
import { setSiteService, getSiteService } from "../services/site";
import { useSelector, useDispatch } from "react-redux";
import { setSiteAccount } from "../reducers/siteSlice";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { message } from "antd";

const SiteCreate = ({ account, accountLimit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_name, setSiteName] = useState("");
  const [site_address, setSiteAddress] = useState("");
  const [site_username, setSiteUsername] = useState("");
  const [site_password, setSitePassword] = useState("");
  const [site_note, setSiteNote] = useState("");

  const dispatch = useDispatch();
  const siteAccount = useSelector((state) => state.site.siteAccount);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleSiteAccountChange = async () => {
    setLoading(true);
    const data = await getSiteService(account.account_uuid);
    dispatch(setSiteAccount(data));
    setLoading(false);
  };

  const handleAddSite = async () => {
    setLoading(true);
    if (siteAccount.length < accountLimit.create_site_limit) {
      setSiteService({
        account_uuid: account.account_uuid,
        site_name: site_name,
        site_address: site_address,
        site_username: site_username,
        site_password: site_password,
        site_note: site_note,
      }).then(async (res) => {
        if (
          res?.message ==
          'duplicate key value violates unique constraint "account_site_site_username_key"'
        ) {
          messageApi.open({
            type: "error",
            content: "ชื่อผู้ใช้นี้ถูกใช้ไปแล้ว",
          });
        } else {
          messageApi.open({
            type: "success",
            content: "เพิ่มหน่วยงานสำเร็จ",
          });
          await handleSiteAccountChange();
        }
      });
    } else {
      messageApi.open({
        type: "error",
        content: `คุณมีโควต้าสำหรับสร้างหน่วยงานทั้งหมด: ${accountLimit.create_site_limit} หน่วยงาน`,
      });
    }
    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleAddSite();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

    // ตรวจสอบว่าข้อมูลที่ผู้ใช้ป้อนเข้ามาตรงกับรูปแบบที่ต้องการหรือไม่
    if (regex.test(value) || value === "") {
      setSiteUsername(value); // ถ้าถูกต้อง ให้ปรับค่า state
    } else {
      messageApi.open({
        type: "error",
        content: "ชื่อผู้ใช้ต้องประกอบด้วย A-Z, a-z, 0-9",
      });
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

    // ตรวจสอบว่าข้อมูลที่ผู้ใช้ป้อนเข้ามาตรงกับรูปแบบที่ต้องการหรือไม่
    if (regex.test(value) || value === "") {
      setSitePassword(value); // ถ้าถูกต้อง ให้ปรับค่า state
    } else {
      messageApi.open({
        type: "error",
        content:
          "ชื่อผู้ใช้ต้องประกอบด้วย A-Z, a-z, 0-9, !@#$%^&*()_+-=[]{};:'|,.<>/?",
      });
    }
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มหน่วยงาน
      </Button>
      {contextHolder}
      <Modal
        title="สร้างบัญชีหน่วยงาน"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่อหน่วยงาน:</p>
        <Input
          value={site_name}
          onChange={(e) => {
            setSiteName(e.target.value);
          }}
          placeholder="ชื่อหน่วยงาน"
        />
        <p>ที่่อยู่หน่วยงาน:</p>
        <Input
          value={site_address}
          onChange={(e) => {
            setSiteAddress(e.target.value);
          }}
          placeholder="ที่อยู่หน่วยงาน"
        />
        <p>ชื่อผู้ใช้:</p>
        <Input
          value={site_username}
          onChange={handleUsernameChange}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="username"
        />
        <p>รหัสผ่าน:</p>
        <Input.Password
          value={site_password}
          onChange={handlePasswordChange}
          prefix={<KeyOutlined className="site-form-item-icon" />}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          placeholder="password"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={site_note}
          onChange={(e) => {
            setSiteNote(e.target.value);
          }}
          rows={4}
          placeholder="หมายเหตุ"
        />
      </Modal>
    </>
  );
};
export default SiteCreate;
