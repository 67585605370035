import React, { useState, useEffect, useRef } from "react";
import { Button, message, Upload, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { detectFace, detectObject, getFace } from "../../services/face";
import { norm } from "mathjs";
import moment from "moment";

function computeSim(feat1, feat2) {
  feat1 = feat1.flat(); // เปลี่ยน feat1 เป็น array 1 มิติ
  feat2 = feat2.flat(); // เปลี่ยน feat2 เป็น array 1 มิติ

  const dotProduct = feat1.reduce(
    (acc, val, index) => acc + val * feat2[index],
    0
  ); // คำนวณ dot product ด้วย reduce
  const normFeat1 = norm(feat1); // คำนวณ norm ของ feat1
  const normFeat2 = norm(feat2); // คำนวณ norm ของ feat2

  const sim = dotProduct / (normFeat1 * normFeat2); // คำนวณค่าความคล้ายคลึง

  return sim;
}

const UploadComponent = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [result, setResult] = useState([]);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);

  const dummyRequest = ({ file, onSuccess }) => {
    detectFace(file)
      .then((res) => {
        setFile(file);
        setData(res.data.data);
        setOpen(true);
        setResult([]);
        message.success(`ประมวลผลใบหน้าเสร็จสิ้น: ${file.name}`);
        onSuccess("ok");
      })
      .catch((error) => {
        console.error("Error detecting face:", error);
        message.error(`ไม่สามารถประมวลผลใบหน้า: ${file.name}`);
        onSuccess("error");
      });
    detectObject(file)
      .then((res) => {
        setFile(file);
        setData1(res.data.data);
        setOpen(true);
        message.success(`ประมวลผลภาพเสร็จสิ้น: ${file.name}`);
        onSuccess("ok");
      })
      .catch((error) => {
        console.error("Error detecting face:", error);
        message.error(`ไม่สามารถประมวลผลภาพ: ${file.name}`);
        onSuccess("error");
      });
  };

  const canvasRef = useRef(null);

  useEffect(() => {
    if (data.length > 0) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        data.forEach((item) => {
          const [x1, y1, x2, y2] = item.bbox;
          const width = x2 - x1;
          const height = y2 - y1;

          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.strokeRect(x1, y1, width, height);
          ctx.font = "16px Arial";
          ctx.fillStyle = "red";
          ctx.fillText(`อายุ: ${item.age}`, x1, y1 - 5);
        });

        data1.forEach(async (item) => {
          var [x, y, w, h] = item.box;

          const left = x - w / 2;
          const top = y - h / 2;
          const width = w;
          const height = h;

          const newLeft = left * (image.width / 640);
          const newTop = top * (image.height / 640);
          const newWidth = width * (image.width / 640);
          const newHeight = height * (image.height / 640);

          const x1 = newLeft;
          const y1 = newTop;

          const rectWidth = newWidth;
          const rectHeight = newHeight;

          ctx.strokeStyle = "green";
          ctx.lineWidth = 2;
          ctx.strokeRect(x1, y1, rectWidth, rectHeight);

          ctx.font = "16px Arial";
          ctx.fillStyle = "green";
          ctx.fillText(`${item.class_name}`, x1, y1 - 5);
        });
      };
    }
  }, [data, data1, file]);

  useEffect(() => {
    getFace(JSON.parse(localStorage.getItem("site"))).then((res) => {
      res.map((feat1) => {
        data.forEach((feat2) => {
          if (
            computeSim(JSON.parse(feat1["embedding"]), feat2["embedding"]) > 0.7
          ) {
            feat1["sim"] = computeSim(
              JSON.parse(feat1["embedding"]),
              feat2["embedding"]
            );
            console.log(feat1["sim"]);
            setResult([...result, feat1]);
          }
        });
      });
    });
  }, [data]);

  return (
    <div className="mr-2">
      <Modal
        title="ระบบค้นหาใบหน้าใกล้เคียง"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <div className="grid grid-cols-1 gap-2">
          <canvas className="w-[100%]" ref={canvasRef} />
          ผลลัพธ์: {result.length} รายการ
          <div className="grid grid-cols-3 gap-2">
            {result.map((item, index) => (
              <div key={index} className="grid grid-cols-1 gap-1 items-center justify-center text-center">
                <img
                  width={150}
                  src={`https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/${item.path}`}
                ></img>
                <div>
                  <p>ความเหมือน: {parseInt(item.sim * 100)}%</p>
                  <p> 
                    {moment(item.created_at.replace("+00:00", "")).format("DD/MM/YYYY HH:mm:ss")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <Upload showUploadList={false} customRequest={dummyRequest}>
        <Button icon={<UserOutlined />}>ค้นหาใบหน้า</Button>
      </Upload>
    </div>
  );
};

export default UploadComponent;
