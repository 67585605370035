import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Layout, theme, Modal, Input, Select, message } from "antd";
import Sidebar from "./components/common/Sidebar";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import SitePage from "./pages/SitePage";
import DashboardVMSPage from "./pages/vms/DashboardPage";
import ProgramVMSPage from "./pages/vms/ProgramPage";
import ProgramPatrolPage from "./pages/patrol/ProgramPage";
import DeviceVMSPage from "./pages/DevicePage";
import NotifyPage from "./pages/NotifyPage";
import ProfilePage from "./pages/ProfilePage";
import OnlyPC from "./pages/OnlyPC";

import DashboardPatrolPage from "./pages/patrol/DashboardPage";

import { useSelector, useDispatch } from "react-redux";

import { getAccount, getAccountLimit } from "./reducers/accountSlice";
import { getAccountService, getAccountLimitService } from "./services/account";
import { setSiteAccount } from "./reducers/siteSlice";
import { getSiteService } from "./services/site";
import { signInWithEmail, signInWithUsername } from "./services/auth";

import { isMobile } from "react-device-detect";

import "./App.css";

const modalStyles = {
  mask: {
    backdropFilter: "blur(5px)",
  },
};

const App = () => {
  const [collapsed, setCollapsed] = useState(true);

  const selectedSystem = useSelector((state) => state.system.system);
  const account = useSelector((state) => state.account.account);
  const accountLimit = useSelector((state) => state.account.accountLimit);
  const selectedSite = useSelector((state) => state.site.site);
  const [accountType, setAccountType] = useState("0");

  const [messageApi, contextHolder] = message.useMessage();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isAuth, setIsAuth] = useState(
    localStorage.getItem("account") !== null
  );

  const dispatch = useDispatch();

  const systemName = {
    1: "vms",
    2: "patrol",
  };

  const handleAccountChange = (value) => {
    dispatch(getAccount(value));
  };

  const handleAccountLimitChange = (value) => {
    dispatch(getAccountLimit(value));
  };

  const handleSignIn = async () => {
    if (localStorage.getItem("accountType") === "1") {
      const data = await signInWithEmail(email, password);
      if (data.user !== null) {
        await getAccountService(data.user.id).then((data) => {
          handleAccountChange(data);
          getSiteService(data.account_uuid).then((data) => {
            dispatch(setSiteAccount(data));
            localStorage.setItem("site", JSON.stringify(data[0]["site_uuid"]));
            getAccountLimitService(data[0]["account_uuid"]).then((data) => {
              handleAccountLimitChange(data);
              window.location.reload();
            });
          });
        });

        setIsAuth(true);
      } else {
        messageApi.open({
          type: "error",
          content: "รหัสผ่านหรือรหัสผ่านไม่ถูกต้อง",
        });
      }
    } else if (localStorage.getItem("accountType") === "0") {
      const data = await signInWithUsername(email, password);
      if (data.length > 0) {
        localStorage.setItem("account", JSON.stringify(data[0]));
        localStorage.setItem("site", JSON.stringify(data[0]["site_uuid"]));
        setIsAuth(true);
        window.location.reload();
      } else {
        messageApi.open({
          type: "error",
          content: "รหัสผ่านหรือรหัสผ่านไม่ถูกต้อง",
        });
      }
    } else {
      messageApi.open({
        type: "error",
        content: "กรุณาเลือกประเภทบัญชี",
      });
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("account") !== null &&
      localStorage.getItem("accountType") === "1"
    ) {
      getAccountService(
        JSON.parse(localStorage.getItem("account"))["account_uuid"]
      ).then((data) => {
        handleAccountChange(data);
        getSiteService(data.account_uuid).then((data) => {
          dispatch(setSiteAccount(data));
        });
      });
      getAccountLimitService(
        JSON.parse(localStorage.getItem("account"))["account_uuid"]
      ).then((data) => {
        handleAccountLimitChange(data);
      });
      setIsAuth(true);
    } else if (
      localStorage.getItem("account") !== null &&
      localStorage.getItem("accountType") === "0"
    ) {
      const data = JSON.parse(localStorage.getItem("account"));
      handleAccountChange(data);
      dispatch(
        setSiteAccount([
          {
            site_name: data["site_name"],
            site_uuid: data["site_uuid"],
          },
        ])
      );
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("system")) {
      localStorage.setItem("system", 2);
    }
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Router>
      {contextHolder}
      <Modal
        open={isMobile}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
      >
        <div className="flex justify-center items-center text-[18px]">
          ปัจจุบันระบบรองรับการใช้งานผ่าน PC เท่านั้น
        </div>
        <div className="flex justify-center items-center">
          "มือถือจะเปิดให้ใช้งาน เริ่มวันที่ 1 เมษายน 2567"
        </div>
        <div className="flex justify-center items-center">
          ขอบคุณที่ใช้งานระบบของเรา
        </div>
      </Modal>
      <Modal
        title="เข้าสู่ระบบบริหารจัดการ"
        closeIcon={false}
        okText="เข้าสู่ระบบ"
        cancelText="ยกเลิก"
        open={!isAuth && !isMobile}
        onOk={handleSignIn}
        styles={modalStyles}
      >
        <p>กรุณาเข้าสู่ระบบเพื่อใช้งาน</p>
        <div className="grid grid-cols-4 gap-2 items-center">
          <p className="col-span-1 subpixel-antialiased">เลือกประเภทบัญชี</p>
          <Select
            className="col-span-3"
            defaultValue="0"
            onSelect={(value) => {
              localStorage.setItem("accountType", value);
              setAccountType(value);
            }}
            onChange={(value) => {
              localStorage.setItem("accountType", value);
              setAccountType(value);
            }}
            options={[
              { value: "0", label: "สำหรับหน่วยงาน" },
              { value: "1", label: "สำหรับบริษัท" },
            ]}
          />
        </div>
        <div className="grid grid-cols-1">
          <Input
            placeholder="ชื่อผู้ใช้"
            size="large"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Input.Password
            className="mt-2"
            size="large"
            placeholder="รหัสผ่าน"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {accountType === "0" && (
            <iframe
              className="mt-4"
              width="100%"
              height="315px"
              src="https://www.youtube.com/embed/SjpC7f9TnN0?si=RiKOgLtZe6n0JKod"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
          {accountType === "1" && (
            <iframe
              className="mt-4"
              width="100%"
              height="315px"
              src="https://www.youtube.com/embed/R4iswin5owk?si=9Gf9FEmE7PKDN6eY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </Modal>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar collapsed={collapsed} />
        <Layout>
          {!isMobile && (
            <Header
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              colorBgContainer={colorBgContainer}
            />
          )}

          <Routes>
            <Route path="/only-pc" element={OnlyPC} />
            <Route
              path="/"
              element={
                selectedSystem === 1 ? (
                  <DashboardVMSPage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                ) : (
                  <DashboardPatrolPage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                )
              }
            />
            <Route
              path="/profile"
              element={
                <ProfilePage
                  account={account}
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                />
              }
            />

            {localStorage.getItem("accountType") === "1" && (
              <>
                <Route
                  path="/system/sites"
                  element={
                    <SitePage
                      account={account}
                      accountLimit={accountLimit}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
                <Route
                  path="/system/program"
                  element={
                    selectedSystem === 1 ? (
                      <ProgramVMSPage
                        system={systemName[selectedSystem]}
                        site_uuid={selectedSite}
                        colorBgContainer={colorBgContainer}
                        borderRadiusLG={borderRadiusLG}
                      />
                    ) : (
                      <ProgramPatrolPage
                        accountLimit={accountLimit}
                        site_uuid={selectedSite}
                        colorBgContainer={colorBgContainer}
                        borderRadiusLG={borderRadiusLG}
                      />
                    )
                  }
                />
                <Route
                  path="/system/devices"
                  element={
                    <DeviceVMSPage
                      system={systemName[selectedSystem]}
                      site_uuid={selectedSite}
                      accountLimit={accountLimit}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
                <Route
                  path="/system/notify"
                  element={
                    <NotifyPage
                      system={systemName[selectedSystem]}
                      site_uuid={selectedSite}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
              </>
            )}
          </Routes>
          <Footer />
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
