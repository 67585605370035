import React from "react";
import { Layout, Select } from "antd";
import CardMonitor from "../../components/dashboard/CardMonitor";
import { SunOutlined, MoonOutlined, TeamOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/plots";
import CountUp from "react-countup";

const { Content: AntdContent } = Layout;

const DemoColumn = () => {
  const data = [];

  const months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  months.forEach((monthName, index) => {
    // สร้างข้อมูลสำหรับเดือนนี้
    const monthData = {
      xField: monthName,
      yField: Math.floor(Math.random() * (100 - 10 + 1)),
      seriesField: "visitor",
    };
    // เพิ่มข้อมูลเดือนลงในอาร์เรย์
    data.push(monthData);

    data.push({
      xField: monthName,
      yField: Math.floor(Math.random() * (100 - 10 + 1)),
      seriesField: "contactor",
    });

    data.push({
      xField: monthName,
      yField: Math.floor(Math.random() * (100 - 10 + 1)),
      seriesField: "suplier",
    });
  });

  const config = {
    data,
    xField: "xField",
    height: 200,
    yField: "yField",
    seriesField: "seriesField",
    colorField: "seriesField",
    label: {
      position: "top",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    meta: {
      seriesField: {
        alias: "ประเภท",
      },
      yField: {
        alias: "จำนวนผู้ติดต่อ (คน)",
      },
    },
  };
  return <Column {...config} />;
};

const ContentComp = ({ colorBgContainer, borderRadiusLG }) => {
  const formatter = (value) => <CountUp end={value} separator="," />;
  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <div className="grid grid-cols-3 gap-3">
        <CardMonitor
          title={"จำนวนผู้ติดต่อรอบกลางวัน (คน)"}
          color={"#FFC13F"}
          icon={<SunOutlined />}
          description={<div className="text-[28px]">{formatter(20)}</div>}
          actions={"ดูรายละเอียด"}
        />
        <CardMonitor
          title={"จำนวนผู้ติดต่อรอบกลางคืน (คน)"}
          color={"#000000"}
          icon={<MoonOutlined />}
          description={<div className="text-[28px]">{formatter(0)}</div>}
          actions={"ดูรายละเอียด"}
        />
        <CardMonitor
          title={"จำนวนผู้ติดต่อที่ยังไม่ออกพื้นที่ (คน)"}
          color={"#FF0000"}
          icon={<TeamOutlined />}
          description={<div className="text-[28px]">{formatter(10)}</div>}
          actions={"ดูรายละเอียด"}
        />
      </div>
      <div className="mt-4 grid grid-cols-1 gap-1">
        <CardMonitor
          title={
            <div className="flex justify-between">
              <div>สถิติการเข้ามาติดต่อ</div>
              <Select
                className="ml-4"
                showSearch
                style={{ width: 200 }}
                placeholder="ปีปัจจุบัน"
                options={[
                  { value: "1", label: "เดือนปัจจุบัน" },
                  { value: "2", label: "เดือนก่อน" },
                  { value: "3", label: "2 เดือน" },
                  { value: "4", label: "3 เดือน" },
                  { value: "5", label: "6 เดือน" },
                  { value: "6", label: "1 ปี" },
                  { value: "6", label: "ปีปัจจุบัน" },
                  { value: "6", label: "ปีก่อน" },
                ]}
              />
            </div>
          }
          actions={
            <div className="w-[80vw]">
              <DemoColumn />
            </div>
          }
        />
      </div>
    </AntdContent>
  );
};

export default ContentComp;
